// @mui material components
import { Grid } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
import VuiCheckbox from "components/VuiCheckbox"
import VuiCard from "components/VuiCard";

// General page component
import VuiInput from "components/VuiInput";

// Data
import React, { useState } from 'react';
function CloudAccountSelector({userClouds, selectedClouds, setSelectedClouds, viewingClouds, setViewingClouds, userCanEdit, determineCloudEnableOrDisable, hasClouds}) {
  //Get system color scheme
  const[colorScheme, setColorScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    setColorScheme(event.matches ? "dark" : "light")
  });

  const [filter, setFilter] = useState(''); //This will filter the userWorkloads for what the user is typing into the input box
  function updateFilter(text){
    setFilter(text.toLowerCase())
    if(text === '')
    {
      setViewingClouds(userClouds.slice(0, 50))
    }
    else
    {
      setViewingClouds(userClouds.filter((workload) => workload.label.toLowerCase().includes(text)).slice(0,50))
    }
  }

  const [selectAll, setSelectAll] = useState(false)
  function updateSelectAll(){
    setSelectAll(!selectAll)
    if(!selectAll)
    {
      viewingClouds.forEach((cloud) => cloud['enabled'] ? cloud['selected'] = true : cloud['selected'] = false)
      setSelectedClouds(userClouds.filter((cloud) => cloud['selected']))
    }
    else
    {
      viewingClouds.forEach((cloud) => cloud['selected'] = false)
      setSelectedClouds([])
    }
  }

  function selectCloud(cloud) {
    if(cloud['selected'])
    {
      cloud['selected'] = false
      selectedClouds.splice(selectedClouds.indexOf(cloud), 1)
      setSelectedClouds(Object.values({...selectedClouds}))
    }
    else
    {
      cloud['selected'] = true
      selectedClouds.push(cloud)
      setSelectedClouds(Object.values({...selectedClouds}))
    }
    if(selectedClouds.length === viewingClouds.length)
    {
      setSelectAll(true)
    }
    else if(selectedClouds.length === 0)
    {
      setSelectAll(false)
    } 
  }

  // eslint-disable-next-line no-unused-vars
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
      setIsHovered(true);
  };

  const handleMouseLeave = () => {
      setIsHovered(false);
  };

return (
  <>
 {userClouds[0] === undefined ? 
    (<>
        <Grid container paddingBottom={2} justifyContent='center'>
          <Grid item>
            <VuiTypography variant="lg" fontWeight="bold" >
              {hasClouds ? 
              (
                <>
                  Loading your cloud accounts...
                </>
              ) : 
              (
                <>
                  No cloud accounts assigned that are eligible for the Universal Notifier. Check your Workload Catalog alignment.
                </>
              )}
            </VuiTypography>
          </Grid>
        </Grid>
      </>) : (
        <VuiCard variant="outlined" sx={{
          zIndex:0,
          overflow:'hidden'
        }}>
        <Grid container>
          <Grid item xs={12}>
            <VuiTypography variant="lg" fontWeight="bold">
              Filter cloud accounts
            </VuiTypography>
            <VuiInput value={filter} placeholder={"Filter cloud accounts"} onChange={e => updateFilter(e.target.value)}/>
          </Grid>
          <Grid item paddingTop={2}>
            <VuiTypography variant="button" fontWeight="bold"> 
              <VuiButton
                variant='text' 
                onClick={() => updateSelectAll()} 
                disabled={!userCanEdit}>
                {selectAll ? "Unselect All" : "Select All"}
              </VuiButton>
            </VuiTypography>
          </Grid>
          <Grid item xs={12} paddingTop={2} paddingBottom={15}>
            <Grid container >
                <Grid item xs>
                  <VuiTypography variant="h5" fontWeight="bold"> 
                    Selected
                  </VuiTypography>
                </Grid>
                <Grid item lg={10} xs={6}>
                  <VuiTypography variant="h5" fontWeight="bold"> 
                    Cloud Account
                  </VuiTypography>
                </Grid>
                <Grid item xs>
                  <VuiTypography variant="h5" fontWeight="bold"> 
                    Enabled
                  </VuiTypography>
                </Grid>
            </Grid>
        </Grid>
        <Grid  
          sx={{
            maxHeight: 500, 
            overflowY: 'auto',
            transition: 'max-height 0.5s ease', 
            maxWidth:'auto',
            '& .MuiGrid-item': {
              minHeight: 100, 
            },
            '&::-webkit-scrollbar': {
              width: '12px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: colorScheme === 'dark' ? '#ffffff' :'#0e5387',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0000',
            },
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          container 
          rowSpacing={10}>
          {(viewingClouds.map((cloud) => 
            <>
              <Grid container spacing={3}>
                <Grid item xs>
                  <VuiCheckbox 
                  checked={cloud['selected']} 
                  onClick={() => selectCloud(cloud)} 
                  disabled={!cloud['notifierEnabled'] || !userCanEdit} />
                </Grid>
                <Grid item lg={10} xs={6}>
                  <VuiTypography variant="lg" fontWeight="bold">
                    {cloud['label']}
                  </VuiTypography>
                  <VuiTypography variant="h6" fontWeight="bold">
                    Number of Vulnerabilities to Expect
                  </VuiTypography>
                  <VuiTypography variant="h6" fontWeight="regular">
                    <Grid container spacing={5} >
                        <Grid item>
                          {<p><span> Crit-{cloud['cloudCriticalFindings']} </span> {'\u00A0'} 
                          <span> High-{cloud['cloudHighFindings']} </span> {'\u00A0'} 
                          <span> Med-{cloud['cloudMediumFindings']} </span></p>}
                        </Grid>
                    </Grid>
                  </VuiTypography>
                  <VuiTypography variant="h6" fontWeight="bold">
                    Current Cloud Severity Setting
                  </VuiTypography>
                  <VuiTypography variant="h6" fontWeight="regular" >
                    <Grid container spacing={5} >
                        <Grid item>
                          {cloud['notifierSeverity'] === "" ? "None" : cloud['notifierSeverity']}
                        </Grid>
                    </Grid>
                  </VuiTypography>
                </Grid>
                <Grid item xs>
                  <VuiSwitch 
                  checked={cloud['notifierEnabled']} 
                  onChange={() => determineCloudEnableOrDisable(cloud)} 
                  />
                </Grid>
              </Grid>
              </>
            ))}  
          </Grid>
        </Grid>
      </VuiCard>
      )}
    
  </>
)
}

export default CloudAccountSelector