// @mui material components
import { Tabs, Tab } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiSnackbar from "components/VuiSnackbar";

// Data
// import * as LDClient from "launchdarkly-js-client-sdk";
import React, { useEffect, useState } from 'react';
// import {useFlags} from "launchdarkly-react-client-sdk";
import { sendPayload, createPostDataOnEnabledWorkload, getUserWorkloads, checkPermissions, getUserClouds, createPostDataOnCloud, sendCloudPayload} from '../../helpers';


//Okta
import { useOktaAuth } from '@okta/okta-react';
import WorkloadSeveritySelector from "../WorkloadSeveritySelector";
import PreferencesWelcome from "../PreferencesWelcome";
import WorkloadsSelector from "../WorkloadsSelector";
import CloudAccountSelector from "../CloudAccountSelector";
import CloudAccountSeveritySelector from "../CloudAccountSeveritySelector";


/*--------------------------------------Start of Code-----------------------------------------*/


function NotifierPreferences() {
  //Get system color scheme
  const[colorScheme, setColorScheme] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    setColorScheme(event.matches ? "dark" : "light")
  });
  //Okta
  const { authState, oktaAuth } = useOktaAuth();
  // eslint-disable-next-line no-unused-vars
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      setUserInfo(authState.idToken.claims);
      // You can also get user information from the `/userinfo` endpoint
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  // const [flagValue, setFlagValue] = useState({"Veracode": true, "Whitehat": false, "DataTheorem": false});
  // const flagsInUse = {"Veracode": "platform-notifier-veracode-prod", "Whitehat": "platform-notifier-whitehat-prod", "DataTheorem": "platform-notifier-dt-prod"}
  /*
    Set up user workloads
  */
  const [userWorkloads, setUserWorkloads] = useState([]);
  const [viewingWorkloads, setViewingWorkloads] = useState([]);
  const [selectedWorkloads, setSelectedWorkloads] = useState([]);
  const [userCanEdit, setUserCanEdit] = useState(true);
  const [hasWorkloads, setHasWorkloads] = useState(true);
  const typesEnabled = ["sca", "sast"]

  //Get users workloads
  useEffect(() => {
    getUserWorkloads(authState.accessToken.accessToken)
    .then((result) => {
      if(result.length !== 0)
      {
          result.forEach((workload) => {
          workload['selected'] = false
        })
        setUserWorkloads(result)
        setViewingWorkloads(result.slice(0, 50))
      }
      else
      {
        setHasWorkloads(false);
      }
    })
    .catch((error) => {
      // Handle errors from getUserWorkloads
      console.error(error);
    });
  }, [authState.accessToken.accessToken])

  /*
    Set up user cloud
  */
    const [userClouds, setUserClouds] = useState([]);
    const [viewingClouds, setViewingClouds] = useState([]);
    const [selectedClouds, setSelectedClouds] = useState([]);
    const [hasClouds, setHasClouds] = useState(true);  
    //Get users clouds
    useEffect(() => {
      getUserClouds(authState.accessToken.accessToken)
      .then((result) => {
        if(result.length !== 0)
        {
            result.forEach((cloud) => {
              cloud['selected'] = false
          })
          setUserClouds(result);
          setViewingClouds(result.slice(0, 50))
        }
        else
        {
          setHasClouds(false);
        }
      })
      .catch((error) => {
        // Handle errors from getUserWorkloads
        console.error(error);
      });
    }, [authState.accessToken.accessToken])

  //Check if logged in user can edit permissions
  useEffect(() => {
    checkPermissions(authState.accessToken.accessToken)
    .then((result) => {
      setUserCanEdit(result?.editPermission)
    })
    .catch((error) => {
      console.error(error);
    });
  }, [authState.accessToken.accessToken])


  function resetSelection()
  {
    selectedWorkloads.forEach((workload) => {
      workload['selected'] = false
    })
    setSelectedWorkloads([])
  }

  function resetCloudSelection(){
    selectedClouds.forEach((cloud) => {
      cloud['selected'] = false
    })
    setSelectedClouds([])
  }
  
  /*
    Enabling and disabling a workload and cloud logic
  */
  const [workloadToEnableOrDisable, setWorkloadToEnableOrDisable] = useState(undefined);
  const [cloudToEnableOrDisable, setCloudToEnableOrDisable] = useState(undefined);

  function determineWorkloadEnableOrDisable(workload)
  {
    setWorkloadToEnableOrDisable(workload)
    if(workload['enabled'])
    {
      openDisableWarningSB(workload)
    }
    else
    {
      openEnableWarningSB(workload)
    }
  }

  const [enableWarningSB, setEnableWarningSB] = useState(false);
  const openEnableWarningSB = (workload) => 
  {
    setEnableWarningSB(true);
  }
  const closeEnableWarningSB = () => setEnableWarningSB(false);

  function renderEnableWarning()
  {
    return (
      <VuiSnackbar
          color="info"
          icon="info"
          title={"Enabling Workload: " + workloadToEnableOrDisable['workloadName']  }

          content={
            <div>
              Enabling a workload will set all available vulnerability types to HIGH. This can be changed after enabling. Would you like to enable?
            </div>
          }
          open={enableWarningSB}
          onClose={closeEnableWarningSB}
          close={closeEnableWarningSB}
          button={true}
          callBack={() => enableWorkload(authState.accessToken.accessToken)}
          cancelCallBack = {() => closeEnableWarningSB()}
        >
        </VuiSnackbar>
    )
  } 

  const [disableWarningSB, setDisableWarningSB] = useState(false);
  const openDisableWarningSB = (workload) => 
  {
    setDisableWarningSB(true);
  }
  const closeDisableWarningSB = () => setDisableWarningSB(false);

  function renderDisableWarning()
  {
    return (
      <VuiSnackbar
          color="error"
          icon="warning"
          title={"Disabling Workload: " + workloadToEnableOrDisable['workloadName']  }

          content={
            <div>
              You are disabling this workload. Are you sure you want to continue?
            </div>
          }
          open={disableWarningSB}
          onClose={closeDisableWarningSB}
          close={closeDisableWarningSB}
          button={true}
          callBack={() => disableWorkload(authState.accessToken.accessToken)}
          cancelCallBack = {() => closeDisableWarningSB()}
        >
        </VuiSnackbar>
    )
  } 

  
  async function enableWorkload(token)
  {
    closeEnableWarningSB();
    const workloadToUpdate = structuredClone(workloadToEnableOrDisable);
    workloadToUpdate['enabled'] = true;
    typesEnabled.map((type) => 
      workloadToUpdate[type] = 'High'
    )
    let payload = createPostDataOnEnabledWorkload(workloadToUpdate);
    const updated = await sendPayload(payload, token)
    if(updated)
    {
      workloadToEnableOrDisable['enabled'] = true;
      typesEnabled.map((type) => 
        workloadToEnableOrDisable[type] = 'High'
      )
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
    }
  }

  async function disableWorkload(token)
  {
    closeDisableWarningSB();
    const workloadToUpdate = structuredClone(workloadToEnableOrDisable);
    workloadToUpdate['enabled'] = false;
    typesEnabled.map((type) => 
      workloadToUpdate[type] = 'None'
    )
    let payload = createPostDataOnEnabledWorkload(workloadToUpdate);
    const updated = await sendPayload(payload, token)
    if(updated)
    {
      workloadToEnableOrDisable['enabled'] = false;
      typesEnabled.map((type) => 
      workloadToEnableOrDisable[type] = 'None'
      )
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
    }
  }

  function determineCloudEnableOrDisable(cloud)
  {
    setCloudToEnableOrDisable(cloud)
    if(cloud['notifierEnabled'])
    {
      openCloudDisableWarningSB(cloud)
    }
    else
    {
      openCloudEnableWarningSB(cloud)
    }
  }

  const [enableCloudWarningSB, setCloudEnableWarningSB] = useState(false);
  const openCloudEnableWarningSB = (cloud) => 
  {
    setCloudEnableWarningSB(true);
  }
  const closeCloudEnableWarningSB = () => setCloudEnableWarningSB(false);

  function renderCloudEnableWarning()
  {
    return (
      <VuiSnackbar
          color="info"
          icon="info"
          title={"Enabling Cloud: " + cloudToEnableOrDisable['accountName']  }

          content={
            <div>
              Enabling a Cloud will set its vulnerability type to HIGH. This can be changed after enabling. Would you like to enable?
            </div>
          }
          open={enableCloudWarningSB}
          onClose={closeCloudEnableWarningSB}
          close={closeCloudEnableWarningSB}
          button={true}
          callBack={() => enableCloud(authState.accessToken.accessToken)}
          cancelCallBack = {() => closeCloudEnableWarningSB()}
        >
        </VuiSnackbar>
    )
  } 

  async function enableCloud(token)
  {
    closeCloudEnableWarningSB();
    const cloudToUpdate = structuredClone(cloudToEnableOrDisable);
    cloudToUpdate['notifierEnabled'] = true;
    cloudToUpdate['notifierSeverity'] = 'High'
    let payload = createPostDataOnCloud(cloudToUpdate);
    const updated = await sendCloudPayload(payload, token)
    if(updated)
    {
      cloudToEnableOrDisable['notifierEnabled'] = true;
      cloudToEnableOrDisable['notifierSeverity'] = 'High'
      
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
    }
  }

  async function disableCloud(token)
  {
    closeCloudDisableWarningSB();
    const cloudToUpdate = structuredClone(cloudToEnableOrDisable);
    cloudToUpdate['notifierEnabled'] = false;
    cloudToUpdate['notifierSeverity'] = 'None'
    
    let payload = createPostDataOnCloud(cloudToUpdate);
    const updated = await sendCloudPayload(payload, token)
    if(updated)
    {
      cloudToEnableOrDisable['notifierEnabled'] = false;
      cloudToEnableOrDisable['notifierSeverity'] = 'None'
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
    }
  }

  const [disableCloudWarningSB, setCloudDisableWarningSB] = useState(false);
  const openCloudDisableWarningSB = (cloud) => 
  {
    setCloudDisableWarningSB(true);
  }
  const closeCloudDisableWarningSB = () => setCloudDisableWarningSB(false);

  function renderCloudDisableWarning()
  {
    return (
      <VuiSnackbar
          color="error"
          icon="warning"
          title={"Disabling Cloud: " + cloudToEnableOrDisable['accountName']  }

          content={
            <div>
              You are disabling this cloud. Are you sure you want to continue?
            </div>
          }
          open={disableCloudWarningSB}
          onClose={closeCloudDisableWarningSB}
          close={closeCloudDisableWarningSB}
          button={true}
          callBack={() => disableCloud(authState.accessToken.accessToken)}
          cancelCallBack = {() => closeCloudDisableWarningSB()}
        >
        </VuiSnackbar>
    )
  } 

  const [savedInfoSB, setOpenSavedInfoSB] = useState(false)
  const opensavedInfoSB = () => setOpenSavedInfoSB(true);
  const closesavedInfoSB = () => setOpenSavedInfoSB(false);

  const renderSavedInfo = (
    <VuiSnackbar
      color="info"
      icon="info"
      title="Preferences Saved"
      content="You have successfully saved your preferences for the Universal Notifier."
      open={savedInfoSB}
      onClose={closesavedInfoSB}
      close={closesavedInfoSB}
      button={false}
    >
    </VuiSnackbar>
  );

  const [couldNotSaveWarningSB, setCouldNotSaveWarningSB] = useState(false)
  const openCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(true);
  const closeCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(false);

  const renderCouldNotSaveWarning = (
    <VuiSnackbar
    color="error"
    icon="warning"
    title="Could not save preferences."
    content="An error has occured, please try again later. If the issue persists please contact the product security engineering team at https://cox.enterprise.slack.com/archives/C02LKMGQ5UJ."
    open={couldNotSaveWarningSB}
    onClose={closeCouldNotSaveWarningSB}
    close={closeCouldNotSaveWarningSB}
    button={false}
  >
  </VuiSnackbar>
  );

  /* Tabs logic
  */
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };
  return (
    <>
        <>
          <PreferencesWelcome userCanEdit={userCanEdit}/>
          <Tabs 
            value={tabValue}
            onChange={handleTabChange}
            sx={{
              '.MuiTabs-indicator': {
                backgroundColor: colorScheme === 'dark' ? '#090e2f' :'#a2aebf', 
              },
              mb:-2,
              ml:1.5,
            }}
            >
            <Tab label={
                <VuiTypography variant="h6" fontWeight="bold">
                  Workloads
                </VuiTypography>
              } 
            />
                {/*<Tab label={*/}
                {/*    <VuiTypography variant="h6" fontWeight="bold">*/}
                {/*      Cloud Accounts*/}
                {/*    </VuiTypography>*/}
                {/*  }*/}
                {/*/>*/}
            </Tabs>

          {tabValue === 0 ?
            (
              <WorkloadsSelector
                userWorkloads={userWorkloads} 
                selectedWorkloads={selectedWorkloads}
                setSelectedWorkloads={setSelectedWorkloads}
                viewingWorkloads={viewingWorkloads}
                setViewingWorkloads={setViewingWorkloads}
                typesEnabled={typesEnabled}
                userCanEdit={userCanEdit}
                determineWorkloadEnableOrDisable={determineWorkloadEnableOrDisable}
                hasWorkloads={hasWorkloads}
              />
            ) : 
            (
              <CloudAccountSelector
                userClouds={userClouds} 
                selectedClouds={selectedClouds}
                setSelectedClouds={setSelectedClouds}
                viewingClouds={viewingClouds}
                setViewingClouds={setViewingClouds}
                userCanEdit={userCanEdit}
                determineCloudEnableOrDisable={determineCloudEnableOrDisable}
                hasClouds={hasClouds}
              />
            )
          }

          {tabValue === 0 ? 
            (
              <WorkloadSeveritySelector 
              userWorkloads={userWorkloads} 
              selectedWorkloads={selectedWorkloads} 
              viewingWorkloads={viewingWorkloads} 
              setViewingWorkloads={setViewingWorkloads}
              resetSelection= {resetSelection}
              typesEnabled={typesEnabled}
              />
            ) : 
            (
              <CloudAccountSeveritySelector
              userClouds={userClouds}
              selectedClouds={selectedClouds}
              resetSelection={resetCloudSelection}
              viewingClouds={viewingClouds}
              setViewingClouds={setViewingClouds}
              />
            )
          }
          {workloadToEnableOrDisable === undefined ? 
            (
              <>
              </>
            ) 
            :
            (
              <>
                {renderEnableWarning()}
                {renderDisableWarning()}
                {renderCouldNotSaveWarning}
                {renderSavedInfo}
              </>
            )
          }
           {cloudToEnableOrDisable === undefined ? 
            (
              <>
              </>
            ) 
            :
            (
              <>
                {renderCloudEnableWarning()}
                {renderCloudDisableWarning()}
                {renderCouldNotSaveWarning}
                {renderSavedInfo}
              </>
            )
          }

         
        </>
    </>
  ); 
}
export default NotifierPreferences;
