

// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import VuiBox from "components/VuiBox";

//  React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// General page components
import NotifierPreferences from "layouts/pages/universal-notifier/components/NotifierPreferences";

function General() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <NotifierPreferences />
      <Footer />
    </DashboardLayout>
  );
}

export default General;
