// @mui material components
import { Grid } from "@mui/material";

//  components
import VuiTypography from "components/VuiTypography";
import VuiSnackbar from "components/VuiSnackbar";
import VuiButton from "components/VuiButton";


// General page components
import VuiSelect from "../../../../../components/VuiSelect";

// Data
import React, { useEffect, useState } from 'react';
import selectData from "../WorkloadSeveritySelector/data";
import { createPostDataOnSelectedClouds, sendCloudPayload} from '../../helpers';

//Okta
import VuiCard from "components/VuiCard";
import { useOktaAuth } from '@okta/okta-react';
function CloudAccountSeveritySelector({userClouds, selectedClouds, resetSelection, viewingClouds, setViewingClouds}) {
  const [cloudSeveritySetting, setCloudSeveritySettingValue] = useState('')
  const [canSelectSeverity, setCanSelectSeverity] = useState(selectedClouds.length > 0)
  useEffect(() => {
    setCanSelectSeverity(selectedClouds.length > 0)
  }, [selectedClouds])
  /*
  * This will be a function to save the preferences that a user has set after they have clicked the save button.
  */
  async function handleSave(token)
  {
    closeSaveWarningSB();
    const cloudsToUpdate = structuredClone(selectedClouds);    
    cloudsToUpdate.forEach((cloud) => {
        cloud['notifierSeverity'] = cloudSeveritySetting === '' ?  cloud['notifierSeverity'] : cloudSeveritySetting
    }) 
    let payload = createPostDataOnSelectedClouds(cloudsToUpdate)
    const updated = await sendCloudPayload(payload, token)
    if(updated)
    {
        selectedClouds.forEach((cloud) => {
          cloud['notifierSeverity'] = cloudSeveritySetting === '' ?  cloud['notifierSeverity'] : cloudSeveritySetting
      })
      setViewingClouds(Object.values({...viewingClouds}))
      opensavedInfoSB()
    }
    else
    {
      openCouldNotSaveWarningSB()
    }
    resetSelection()
  }

  const [savedInfoSB, setOpenSavedInfoSB] = useState(false)
  const opensavedInfoSB = () => setOpenSavedInfoSB(true);
  const closesavedInfoSB = () => setOpenSavedInfoSB(false);

  const renderSavedInfo = (
      <VuiSnackbar
      color="info"
      icon="info"
      title="Preferences Saved"
      content="You have successfully saved your preferences for the Universal Notifier."
      open={savedInfoSB}
      onClose={closesavedInfoSB}
      close={closesavedInfoSB}
      button={false}
    >
    </VuiSnackbar>
  );

  const [couldNotSaveWarningSB, setCouldNotSaveWarningSB] = useState(false)
  const openCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(true);
  const closeCouldNotSaveWarningSB = () => setCouldNotSaveWarningSB(false);

  const renderCouldNotSaveWarning = (
    <VuiSnackbar
        color="error"
        icon="warning"
        title="Could not save preferences."
        content="You either do not have permissions to edit the Universal Notifier Preferences or the API call failed. Most likely you do not have correct permissions.
        Please contact the Product Security Engineering team if the issue persists and you are sure you have correct permissions."
        open={couldNotSaveWarningSB}
        onClose={closeCouldNotSaveWarningSB}
        close={closeCouldNotSaveWarningSB}
        button={false}
      >
      </VuiSnackbar>
  );
  

  const [saveWarningSB, setSaveWarningSB] = useState(false);
  const openSaveWarningSB = () => setSaveWarningSB(true);
  const closeSaveWarningSB = () => setSaveWarningSB(false);

  function saveWarning(token) {
      return <VuiSnackbar
          color="info"
          icon="warning"
          title="Saving new preferences"
          content="Are you sure you want to make these changes to the workload?"
          open={saveWarningSB}
          onClose={closeSaveWarningSB}
          close={closeSaveWarningSB}
          button={true}
          callBack = {() => handleSave(token)}
          cancelCallBack = {() => closeSaveWarningSB()}
      >
      </VuiSnackbar>
  }


  function handleChange(severity){
    setCloudSeveritySettingValue(severity)
  }

  const { authState } = useOktaAuth();
  return (
    <>
        {canSelectSeverity ? 
        (
            <VuiCard sx={{
                zIndex:10,
                overflow:'visible',
                marginTop:5,
                position: 'sticky',
                bottom: 20,
                }}
                hasBorder={true}>
                <Grid container spacing={3} >
                    <Grid item xs='auto' sx={{ justifyContent: 'flex-start' }}> 
                        <VuiTypography variant="lg" fontWeight="bold">
                        Cloud Severity
                        </VuiTypography>
                    </Grid>
                    <Grid item xs={2} sx={{  justifyContent: 'flex-start' }}>
                        <VuiSelect 
                        name="Cloud Severity"
                        placeholder='Select Severity' 
                        options={selectData.severity} 
                        onChange={(data, name) => handleChange(data.value)} 
                        disabled={selectedClouds.length === 0 ? true : false}
                        menuPlacement="top"
                        />
                    </Grid>
                  <Grid item xs>
                  {/* This item acts as a flexible spacer so that the Save button can appear on the same row but spaced out */}
                  </Grid>
                  <Grid item sx={{ justifyContent: 'flex-end' }}>
                    <VuiButton onClick={() => openSaveWarningSB()} disabled={selectedClouds.length === 0}>
                        Save
                    </VuiButton>
                  </Grid>
                </Grid>
            </VuiCard>
              
        ) : 
        (
            <>
            </>
        )
        }
        {saveWarning(authState.accessToken.accessToken)}
        {renderCouldNotSaveWarning}
        {renderSavedInfo}
      
    </>
  )
}

export default CloudAccountSeveritySelector